
import { Options, Vue } from 'vue-class-component';
import { BaseChatClient, ChatRoomClient, UserClient } from '@/services/Services';
import ChatComponent from '@/components/chat/chatComponent.vue';
import ChatHeader from '../components/chatHeader.vue';
import ChatSettingsModal from '../modals/chatSettingsModal.vue';
// import CustomConfirm from '@/components/customConfirm.vue';
// import OpenProfileModal from '../components/openProfileModal.vue';
import store from '@/store';
import * as OM from '@/model';
import * as Const from '@/const';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        ChatComponent,
        ChatHeader
    }
})
export default class ChatBase extends Vue {

    loading: boolean = true;
    isError: boolean = false;
    chatVm: OM.ChatListVm = null;

    created() {
        store.state.chatRoomAllowances = null;
        this.loading = true;
        BaseChatClient.getSingleChatListVm(this.$route.params.identifier.toString())
        .then(x => {
            this.chatVm = x;
            if(this.chatVm.type == Const.ChatRoomType.ChatRoom){
                ChatRoomClient.getChatRoomAllowances(this.$route.params.identifier.toString())
                .then(x => {
                    store.state.chatRoomAllowances = x;
                })
            }

            this.loading = false;
        })
        .catch( ex => {
            this.isError = true;
            this.loading = false
            this.$opModal.show(ModalSuccessError, {
                isError: true,
                message: LocalizationServices.getLocalizedValue("app_SembraCheQuestaConversazioneNonEsistaPiù!", "Sembra che questa conversazione non esista più!"),
                buttonUrl: "/notification"
            })
        });
    }

    get canWrite() {
        if(store.state.chatRoomAllowances){
            return store.state.chatRoomAllowances.canContent;
        }
        return true;
    }

    settingsModal() {
        this.$opModal.show(ChatSettingsModal, {
            chatName: this.chatVm.name,
            userIdentifier: this.chatVm.userIdentifier,
            isGroupChat: this.chatVm.type == Const.ChatRoomType.ChatRoom
        })
    }

    openDetail() {
            if(this.chatVm.type == "ConversationRoom"){
                this.$router.push('/profile/' + this.chatVm.userIdentifier);
            } else if(this.chatVm.type == "ChatRoom"){
                this.$router.push('/chats/detail/' + this.chatVm.identifier);
            } 
        }
        // this.$opModal.show(OpenProfileModal, {
        //     title: this.chatVm.name,
        //     userIdentifier: this.chatVm.userIdentifier,
        //     openProfile: () => {
        //         this.$opModal.closeLast();

        //         if(this.chatVm.type == "ConversationRoom"){
        //             this.$router.push('/profile/' + this.chatVm.userIdentifier);
        //         } else if(this.chatVm.type == "ChatRoom"){
        //             this.$router.push('/chats/detail/' + this.chatVm.identifier);
        //         }
        //     },
        //     blockUser: () => {
        //         var title = LocalizationServices.getLocalizedValue("app_attenzione", "Attenzione!");
        //         var text = LocalizationServices.getLocalizedValue("app_vuoidavverobloccarequestoutente", "Vuoi davvero bloccare questo utente?");

        //         this.$opModal.show(CustomConfirm, {
        //             title: title,
        //             text: text,
        //             callback: (value: boolean) => {
        //                 if(!value)
        //                     this.$opModal.closeLast();
        //                 else {
        //                     UserClient.blockUser(this.chatVm.userIdentifier)
        //                     .then( x => {
        //                         this.$router.replace("/chats");
        //                         this.$opModal.closeLast();
        //                     })
        //                 }
        //             }

        //         })
        //     }
        //})

    }



